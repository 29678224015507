* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

body {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

::-webkit-scrollbar {
  display: none;
}

.navbar {
  position: fixed;
}

/****register****/

.bg-image {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    url(./img/bg.jpg);
  width: 100% !important;
  background-size: cover;
  background-position: center;
  /* background-attachment: fixed; */
}

.background {
  background-color: transparent !important;
  /* background-color: rgba(0, 0, 0, 0.3); */
  border: none;
}

/* .style::placeholder {
  color: #fff;
} */
/*******/

.nav-con {
  margin-left: 50px;
  margin-right: 50px;
}

.navbar-brand {
  font-weight: 600 !important;
}

h1 {
  font-size: 20px !important;
}

.flex-container {
  display: flex !important;
}

select {
  box-shadow: none !important;
}

.bg-img {
  mix-blend-mode: darken;
}

.logo {
  width: 100px;
  height: 60px;
}

.banner-con {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 100%;
}

.banner-img {
  border-radius: 6px;
  width: 100%;
  max-width: 90%;
  height: 85vh;
}

/* .bg-img {
  background-image: url("./img/bg.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  
} */

/*******cart****/

.margin {
  margin-top: 100px;
}

/*******card hover *****/

.cards {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background: #fff;
  transition: all 0.5s ease;
  cursor: pointer;
  user-select: none;
  z-index: 10;
  overflow: hidden;
}

/* .card .backgroundEffect {
  bottom: 0;
  height: 0px;
  width: 100%;
} */

.cards:hover {
  color: #000;
  transform: scale(1.08);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 5px 10px;
}
/***********************Footer**************************/

/* footer {
  margin-top: auto;
} */

.main-con {
  background-color: black;
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.footer-con {
  display: flex;
  justify-content: center;
  color: #fff;
  font-size: 12px;
}

.list-con {
  margin-top: 50px;
  margin-left: 80px;
}

.list-con p {
  text-align: center;
  font-size: 16px;
}
/* .list-con ul li {
  margin-top: 10px;
} */

.para {
  margin-top: 120px;
  text-align: center;
  font-size: 18px;
  color: #fff;
  width: 60%;
}

@media screen and (max-width: 768px) {
  .banner-img {
    width: 100%;
    max-width: 90%;

    height: 50vh;
  }

  .bg-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left;
  }

  .margint {
    margin-top: 80px;
  }
  .main-con {
    width: 100%;
    /* height: 40vh; */
  }

  .footer-con {
    width: 60%;
    display: flex;
    flex-wrap: wrap;
    color: #fff;
    font-size: 12px;
  }

  .para {
    margin-top: 80px;
    text-align: center;
    font-size: 12px;
    color: #fff;
    width: 80%;
  }
}

/* @media screen and (max-width: 1024px) {
  .bg-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
  }
} */

@media screen and (max-width: 576px) {
  .main-con {
    height: auto;
    display: flex;
    justify-content: center;
  }
  .footer-con {
    width: 100%;

    flex-wrap: wrap;
    color: #fff;
    font-size: 10px;
    margin-right: 20px;
    bottom: 0;
  }

  .para {
    margin-top: 80px;
    text-align: center;
    font-size: 12px;
    color: #fff;
    width: 100%;
  }
}
